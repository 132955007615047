<template>
  <div>
    <el-button size="mini" type="primary" @click="goBack">返回列表</el-button>
    <div class="page-content-main">
      <el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" class="margin-top"
               style="width:600px;">
        <el-form-item label="所属广告位" prop="type_banner_id">
          <type-select v-model="formData.type_banner_id" url="/SystemDict/list" :data="{type:'banner'}" :can-add="false"
                       :is-top="false"></type-select>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="formData.title" maxlength="20" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="资源类型" prop="res_type">
          <radio-group v-model="formData.res_type" :options="[{label:'图片',value:'image'},{label:'视频',value:'video'}]" @change="formData.res_url=''"></radio-group>
        </el-form-item>
        <el-form-item label="资源" prop="res_url">
          <head-img-upload v-model="formData.res_url"></head-img-upload>
        </el-form-item>
        <el-form-item label="描述" prop="des">
          <el-input type="textarea" v-model="formData.des" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input type="textarea" v-model="formData.remark" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="URL地址" prop="out_url" v-if="formData.res_type === 'image'">
          <el-input v-model="formData.out_url" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort">
          <el-input v-model="formData.sort" maxlength="20" show-word-limit style="width:300px;"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="state">
          <el-switch
            v-model="formData.state"
            active-color="#13ce66"
            :active-value="1"
            :inactive-value="0"
            active-text="启用"
            inactive-text="禁用">
          </el-switch>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('form')">确定</el-button>
          <el-button type="" @click="$router.back()">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoodsSpecAdd",
  data() {
    return {
      formData: {
        title: '',
        out_url: '',
        res_type: 'image',
        type_banner_id: '',
        state: 1,
        sort: 0,
        res_url: '',
        des:'',
        remark:''
      },
      typeList: [],
      rules: {
        res_url: [
          {required: true, message: '图片必传'},
        ],
        res_type: [
          {required: true},
        ],
        type_banner_id: [
          {required: true},
        ],
        state: [
          {required: true},
        ],
        sort: [
          {required: true},
        ],
      },
    };
  },
  created() {
    this.formData.type_banner_id = this.$route.query.id ? this.$route.query.id : 0;
  },
  mounted() {
    if (this.formData.type_banner_id) {
      // this.getTypeList();
    }
  },
  methods: {
    submitForm(formName) {
      let _this = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(_this.formData);
          _this.$http.request({
            url: '/Banner/add',
            datas: _this.formData,
            success: (res) => {
              _this.$user.removeTab();
              _this.$router.back();
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    goBack(){
      this.$user.removeTab();
      this.$router.back();
    }
  }
}
</script>
